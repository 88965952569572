<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <b-row>
      <b-col md="12 mt-1">
        <b-alert show variant="warning" v-if="cloud_type === 'aws'">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50 mt-25" />
            <span class="font-italic">
              Ensure that the credentials used are associated with a user or
              role possessing sufficient permissions to perform all checks.
              <br />
              To ensure comprehensive access, add the following AWS managed
              policies to the user or role:
            </span>

            <ul>
              <li>arn:aws:iam::aws:policy/SecurityAudit</li>
              <li>arn:aws:iam::aws:policy/job-function/ViewOnlyAccess</li>
            </ul>

            <span class="font-italic">
              Attaching these policies will enable the necessary permissions for
              thorough security evaluations.
            </span>
          </div>
        </b-alert>

        <!-- for azure select  -->
        <b-alert show variant="warning" v-if="cloud_type === 'azure'">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50 mt-25" />
            <span class="font-italic">
              Ensure that the credentials used are associated with a user or
              role possessing the necessary permissions to conduct all
              checks.<br />
              The Microsoft Enterprise Identity permissions required by the tool
              are as follows:
            </span>

            <ul>
              <li>Directory.Read.All</li>
              <li>Policy.Read.All</li>
              <li>UserAuthenticationMethod.Read.All</li>
              <li>Security Reader</li>
              <li>Reader</li>
            </ul>

            <span class="font-italic">
              Ensure that these permissions are assigned to the user or role
              being utilized for comprehensive security assessments.
            </span>
          </div>
        </b-alert>
        <!-- for google cloud -->
        <b-alert show variant="warning" v-if="cloud_type === 'gcp'">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50 mt-25" />
            <span class="font-italic">
              For comprehensive security scans, use a service account. Ensure
              the credentials have sufficient permissions.<br />
              Add the following Google Cloud Platform managed policy to the
              user:
            </span>

            <ul>
              <li>Monitoring Viewer</li>
              <li>Compute Viewer</li>
            </ul>

            <span class="font-italic">
              To begin, activate the following APIs in your Google Cloud
              Platform (GCP) project:
            </span>
            <ul>
              <li>Service Usage API</li>
              <li>Cloud Resource Manager API</li>
              <li>Compute Engine API</li>
            </ul>
            <span class="font-italic">
              Should you be utilizing any other GCP services within this
              project, you might also need to activate their corresponding APIs.
            </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <validation-observer ref="CloudConfigAddForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="addCloudConfig">
        <!-- Cloud type-->
        <b-form-group label="cloud type" label-for="cloud type">
          <template #label>
            <span>Cloud Type</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="cloud type"
            rules="required"
          >
            <v-select
              v-model="cloud_type"
              label="text"
              placeholder="--Select cloud type--"
              :options="cloud_type_options"
              autocomplete
              :reduce="(cloud) => cloud.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- cloud Name -->
        <b-form-group label="cloud name" label-for="cloud name">
          <template #label>
            <span>Cloud Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Cloud Name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="cloud_name"
              v-model="cloud_nice_name"
              :state="errors.length > 0 ? false : null"
              name="cloud_name"
              placeholder="Please enter cloud name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- only for aws select -->
        <div v-if="cloud_type === 'aws'">
          <b-form-group>
            <template #label>
              <span>Access Key</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="access key"
              vid="access key"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model="access_key"
                  :state="errors.length > 0 ? false : null"
                  :type="passwordFieldType"
                  name="access_key"
                  placeholder="Please enter access key"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template #label>
              <span>Secret Key</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="secret key"
              vid="secret_key"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model="secret_key"
                  :state="errors.length > 0 ? false : null"
                  :type="passwordFieldType1"
                  name="secret key"
                  placeholder="Please enter secret key"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon1"
                    @click="togglePasswordVisibility1"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <!-- only of azure  -->
        <div v-if="cloud_type === 'azure'">
          <b-form-group>
            <template #label>
              <span>Tenent ID</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="tenant id"
              vid="tenant_id"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model="tenant_id"
                  :state="errors.length > 0 ? false : null"
                  :type="passwordFieldType2"
                  name="tenant id"
                  placeholder="Please enter tenent id"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon2"
                    @click="togglePasswordVisibility2"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template #label>
              <span>Client ID</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="client id"
              vid="client id"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model="client_id"
                  :state="errors.length > 0 ? false : null"
                  :type="passwordFieldType3"
                  name="client id"
                  placeholder="Please enter client ID"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon3"
                    @click="togglePasswordVisibility3"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template #label>
              <span>Client Secret</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="client secret"
              vid="client_secret"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model="client_secret"
                  :state="errors.length > 0 ? false : null"
                  :type="passwordFieldType4"
                  name="client_secret"
                  placeholder="Please enter client secret"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon4"
                    @click="togglePasswordVisibility4"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <!-- only of azure  -->
        <div v-if="cloud_type === 'gcp'">
          <b-form-group label="Upload File" label-for="File">
            <template #label>
              <span>Upload File</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <b-form-file
              v-model="gcp_file"
              :state="Boolean(gcp_file)"
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload()"
            ></b-form-file>
            <div class="mt-2">
              Selected file: {{ gcp_file ? gcp_file.name : "" }}
            </div>
          </b-form-group>
        </div>

        <b-button
          type="submit"
          variant="primary"
          :disabled="cloud_nice_name === '' || disable"
          class="my-1"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Add</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    vSelect,
    BAlert,
  },
  // mixins: [togglePasswordVisibility],
  data() {
    return {
      cloud_nice_name: "",
      cloud_type: "",
      cloud_type_options: [
        { value: "aws", text: "AWS" },
        { value: "azure", text: "Azure" },
        { value: "gcp", text: "Google Cloud" },
        // { value: "kubernetes", text: "Kubernetes" },
      ],
      isloading: false,
      disable: false,
      access_key: "",
      secret_key: "",
      client_id: "",
      tenant_id: "",
      client_secret: "",
      passwordFieldType: "password",
      passwordFieldType1: "password",
      passwordFieldType2: "password",
      passwordFieldType3: "password",
      passwordFieldType4: "password",
      gcp_file: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },

    passwordToggleIcon1() {
      return this.passwordFieldType1 === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIcon2() {
      return this.passwordFieldType2 === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIcon3() {
      return this.passwordFieldType3 === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIcon4() {
      return this.passwordFieldType4 === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created: function () {
    // this.load();
  },

  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    togglePasswordVisibility1() {
      this.passwordFieldType1 =
        this.passwordFieldType1 === "password" ? "text" : "password";
    },
    togglePasswordVisibility2() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
    },
    togglePasswordVisibility3() {
      this.passwordFieldType3 =
        this.passwordFieldType3 === "password" ? "text" : "password";
    },
    togglePasswordVisibility4() {
      this.passwordFieldType4 =
        this.passwordFieldType4 === "password" ? "text" : "password";
    },
    addCloudConfig: function () {
      this.$refs.CloudConfigAddForm.validate().then((success) => {
        if (!success) {
          return; // Stop if form is not valid
        }
        this.disable = true;
        this.isloading = true;

        let dataToSend;
        let headers = {};
        const baseURL =
          process.env.VUE_APP_BASEURL + "organization/cloud-config/";

        // Prepare data based on the cloud type
        if (this.cloud_type === "aws" || this.cloud_type === "azure") {
          let data = {
            cloud_type: this.cloud_type,
            nice_name: this.cloud_nice_name,
          };
          if (this.cloud_type === "aws") {
            data.access_key = this.access_key;
            data.secret_key = this.secret_key;
          }
          if (this.cloud_type === "azure") {
            data.client_id = this.client_id;
            data.tenant_id = this.tenant_id;
            data.client_secret = this.client_secret;
          }
          dataToSend = data;
          headers["Content-Type"] = "application/json";
        } else if (this.cloud_type === "gcp") {
          let formData = new FormData();
          formData.append("cloud_type", this.cloud_type);
          formData.append("nice_name", this.cloud_nice_name);
          formData.append("gcp_cred_file", this.gcp_file);
          dataToSend = formData;
        }

        // Perform the HTTP request
        this.$http({
          method: "POST",
          url: baseURL,
          data: dataToSend,
          headers: headers,
        })
          .then((response) => {
            if (response.data && response.data.id) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Cloud Configuration added successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.push({
                name: "Cloud-Configuration",
                query: { tabIndex: 1 },
              });
            }
          })
          .catch((error) => {
            console.error(
              "There was an error adding the cloud configuration:",
              error
            );
          })
          .finally(() => {
            this.isloading = false;
            this.disable = false;
          });
      });
    },
    // addCloudConfig: function () {
    //   this.$refs.CloudConfigAddForm.validate()
    //     .then((success) => {
    //       debugger
    //       this.disable = true;
    //       this.isloading = true;
    //       if (success) {
    //         var self = this;
    //         if (this.cloud_type === "aws" || this.cloud_type === "azure") {
    //           let data = {
    //             cloud_type: this.cloud_type,
    //             nice_name: this.cloud_nice_name,
    //           };
    //           if (this.cloud_type === "aws") {
    //             data["access_key"] = this.access_key;
    //             data["secret_key"] = this.secret_key;
    //           }
    //           if (this.cloud_type === "azure") {
    //             data["client_id"] = this.client_id;
    //             data["tenant_id"] = this.tenant_id;
    //             data["client_secret"] = this.client_secret;
    //           }
    //         }
    //         if (this.cloud_type === "gcp") {
    //           let formData = new FormData();
    //           formData.append("cloud_type", this.cloud_type);
    //           formData.append("nice_name", this.cloud_nice_name);
    //           formData.append("gcp_cred_file", this.gcp_file);
    //         }

    //         const options = {
    //           method: "POST",
    //           headers: { "content-type": "application/json" },
    //           data: data,
    //           url: process.env.VUE_APP_BASEURL + "organization/cloud-config/",
    //         };
    //         this.$http(options).then((res) => {
    //           if (res.data.id) {
    //             self.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: "Cloud Configuration added successfully",
    //                 icon: "EditIcon",
    //                 variant: "success",
    //               },
    //             });
    //             self.$router.push({
    //               name: "Cloud-Configuration",
    //               query: { tabIndex: 1 },
    //             });
    //           }

    //         });
    //       }
    //     })
    //     .catch((err) => {

    //     })
    //     .finally(() => {
    //       this.isloading = false;
    //       this.disable = false;
    //     });
    // },
    handleFileUpload() {
      this.gcp_file = this.$refs.file.files[0];
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>